<template>
  <div
    v-show="show"
    v-if="showOverlay"
    :class="overlayClass"
    v-bind="$attrs"
    :style="overlaySpinnerStyle">
    <div class="spinner-wrapper">
      <div v-if="showSpinner" :class="'spinner-' + type">
        <div v-for="index of 9" v-if="type == 'cubes'" class="cube"></div>
      </div>
      <div v-if="hasText" class="spinner-text">{{ finalSpinnerText }}</div>
      <slot></slot>
      <progress
        v-if="hasProgress"
        class="progress"
        :value="progress"
        max="100"></progress>
    </div>
  </div>

  <div
    v-if="!showOverlay"
    v-show="show"
    :style="noOverlaySpinnerStyle"
    :class="nonOverlayWrapperClass"
    v-bind="$attrs">
    <div class="inner gap-m">
      <div v-if="showSpinner" :class="'spinner-' + type">
        <div v-for="index of 9" v-if="type == 'cubes'" class="cube"></div>
      </div>
      <div v-if="hasText" v-show="show" class="spinner-text">
        {{ translate(text) }}
      </div>
      <slot></slot>
      <progress
        v-if="hasProgress"
        v-show="show"
        class="progress"
        :value="progress"
        max="100"></progress>
    </div>
  </div>
</template>

<script>
/**
 * Spinner
 * @displayName spinner (frameworks/uikit - loaders)
 */
import SpinnerMixin from "@/client/extensions/mixins/baseComponents/loaders/spinner.js";

export default {
  mixins: [SpinnerMixin],
  props: {
    type: {
      type: String,
      default: config.style.defaultSpinnerType,
    },
    /**
     * Color expressed as a shortened css variable
     * false = use currentColor
     */
    color: {
      type: [String, Boolean],
      default: "lead",
    },
    spinnerHeight: {
      type: [String, Boolean],
      default: false,
    },
    spinnerWidth: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    spinnerAttributes: function () {
      return "ratio: " + this.computedRatio;
    },
    showOverlay: function () {
      return (
        this.overlay === "absolute" || this.overlay === "fixed" || this.overlay
      );
    },
    overlayClass: function () {
      let val = this.overlay;
      if (this.overlay === true) {
        val = "default";
      }

      return [
        "spinner-overlay-wrapper",
        "spinner-overlay-wrapper--" + val,
        this.class,
      ];
    },
    nonOverlayWrapperClass: function () {
      let classes = [
        "spinner-wrapper",
        "spinner-wrapper--no-overlay",
        this.class,
      ];
      if (this.inline) {
        classes.push("spinner-wrapper--inline");
      }
      return classes;
    },
    overlayBackground: function () {
      let val = "rgba(255,255,255,0.8)";

      if (typeof this.overlayOpacity === "string") {
        switch (this.overlayOpacity) {
          case "none":
          case "transparent":
            return "rgba(255,255,255,0.00)";
            break;
          case "light":
            return "rgba(255,255,255,0.20)";
            break;
          case "inverse":
            return "rgba(255,255,255,0.85)";
            break;
        }
      }

      if (typeof this.overlayOpacity === "number") {
        return "rgba(255,255,255," + this.overlayOpacity + ")";
      }

      // use default
      return val;
    },
    overlaySpinnerStyle() {
      return { backgroundColor: this.overlayBackground, cursor: this.cursor };
    },
    noOverlaySpinnerStyle() {
      return { cursor: this.cursor };
    },
    safeRatio() {
      let size = this.computedRatio;
      if (typeof size === "boolean") {
        return 1;
      }
      return Number(size);
    },
    heightProperty() {
      return this.spinnerHeight ? this.spinnerHeight : `${this.safeRatio}em`;
    },
    widthProperty() {
      return this.spinnerWidth ? this.spinnerWidth : `${this.safeRatio}em`;
    },
    computedColor() {
      if ([false, "auto", "currentColor"].includes(this.color)) {
        return "currentColor";
      }

      return "var(--c-" + this.color + ")";
    },
  },
};
</script>

<style scoped lang="scss">
.spinner-overlay-wrapper {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: var(--z-top-2);
  background: rgba(255, 255, 255, 0.45);
  display: flex;
  justify-content: center;
  text-align: center;

  &.spinner-overlay-wrapper--absolute {
    position: absolute;
  }

  &.spinner-overlay-wrapper--fixed {
    position: fixed;
  }

  .spinner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .spinner-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.spinner-wrapper--inline,
.spinner-wrapper--no-overlay.spinner-wrapper--inline {
  display: inline-block;
  vertical-align: middle;
}

.spinner-wrapper--no-overlay {
  display: flex;

  .inner {
    display: flex;
    align-items: center;

    > * {
      -webkit-margin-inline-end: 0.5em;
      margin-inline-end: 0.5em;

      &:last-child {
        -webkit-margin-inline-end: 0.5em;
        margin-inline-end: 0.5em;
      }
    }
  }
}

.spinner-circle,
.spinner-cubes {
  height: v-bind(heightProperty);
  width: v-bind(widthProperty);
  color: v-bind(computedColor);
}
</style>

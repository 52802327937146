export default {
  props: {
    /**
     * Is the spinner showing? When false, this has no html output
     * @values true, false
     */
    show: {
      type: Boolean,
      default: true,
    },
    /**
     * Show the actual spinner
     **/
    showSpinner: {
      type: Boolean,
      default: true,
    },
    /**
     * Size of spinner For this uikit implementation, this is used as the spinner ratio
     * If set to true (boolean) will use size based on type of overlay
     * @see https://getuikit.com/docs/spinner#ratio
     *
     */
    size: {
      type: [Number, String, Boolean],
      default: true,
    },
    /**
     * Should this be shown as an overlay?
     * Uses CSS styles for fixed to cover entire page,
     * absolute cover entire positioned parent
     *
     * Any value other than fixed or absolute is evaluated to false
     *
     * @values fixed, absolute, *
     *
     */
    overlay: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Should the outer wrapper of the spinner component be inline
     * @values true, false
     */
    inline: {
      type: [Boolean],
      default: false,
    },
    /**
     * Text to show next to the spinner. Default is not to show text
     *
     */
    text: {
      type: [String],
      default: "",
    },
    /**
     * Class for external wrapper
     */
    class: {
      type: [String],
      default: "",
    },
    /**
     * Should a progress bar be shown?
     * Defaults to false. If a number is provided, progress bar will be shown
     * with [number]% progress.
     */
    progress: {
      type: [Boolean, Number],
      default: false,
    },
    /**
     * If using overlay, should we override the default opacity
     * If number is provided, this will be the opacity (rgba(255,255,255,[number])
     * If "light" or "dark" a preset value is used. TODO: support low/hight contrast names instead of or in addition to "light" and "dark"
     * Any other value results in the default
     * @values light, dark, [integer]
     */
    overlayOpacity: {
      type: [Boolean, Number, String],
      default: false,
    },
    /**
     * Css mouse courser
     */
    cursor: {
      type: String,
      default: "wait",
    },
    /**
     * Auto translate spinner text?
     */
    autoTranslate: {
      type: Boolean,
      default: true,
    },
    textParams: {
      type: [Boolean, Object],
      default: false,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    computedRatio() {
      let ratio = this.size;

      if (ratio === true && this.overlay === "absolute") {
        return 2;
      }

      if (ratio === true && this.overlay === "fixed") {
        return 3;
      }

      if (ratio === true) {
        return 1;
      }

      return ratio;
    },
    spinnerAttributes: function () {
      return "ratio: " + this.computedRatio;
    },
    showOverlay: function () {
      return (
        this.overlay === "absolute" || this.overlay === "fixed" || this.overlay
      );
    },
    overlayClass: function () {
      let val = this.overlay;
      if (this.overlay === true) {
        val = "default";
      }

      return [
        "spinner-overlay-wrapper",
        "spinner-overlay-wrapper--" + val,
        this.class,
      ];
    },
    nonOverlayWrapperClass: function () {
      let classes = [
        "spinner-wrapper",
        "spinner-wrapper--no-overlay",
        this.class,
      ];
      if (this.inline) {
        classes.push("spinner-wrapper--inline");
      }
      return classes;
    },
    hasText: function () {
      return this.text !== "";
    },
    hasProgress: function () {
      return typeof this.progress === "number";
    },
    overlayBackground: function () {
      let val = "rgba(255,255,255,0.8)";

      if (typeof this.overlayOpacity === "string") {
        switch (this.overlayOpacity) {
          case "none":
          case "transparent":
            return "rgba(255,255,255,0.00)";
            break;
          case "light":
            return "rgba(255,255,255,0.20)";
            break;
          case "dark":
            return "rgba(255,255,255,0.85)";
            break;
        }
      }

      if (typeof this.overlayOpacity === "number") {
        return "rgba(255,255,255," + this.overlayOpacity + ")";
      }

      // use default
      return val;
    },
    overlaySpinnerStyle() {
      return { backgroundColor: this.overlayBackground, cursor: this.cursor };
    },
    noOverlaySpinnerStyle() {
      return { cursor: this.cursor };
    },
    finalSpinnerText() {
      if (!this.autoTranslate) {
        return this.text;
      }

      if (this.textParams) {
        return this.translate(this.text, this.textParams);
      }
      return this.translate(this.text);
    },
  },
};
